import React from 'react'
import Layout from '../components/Layout'
import Hero from '../components/ListingHero'
import Routes from '../utilities/Routes'
import BreadCrumbComponent from '../components/BreadCrumbComponent'
import CaseStudyCards from '../components/CaseStudyCards'
import { graphql } from 'gatsby'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  container: {
    padding: "20px",
    [theme.breakpoints.up('sm')]: {
      padding: "2rem"
    }
  }
}))

function CaseStudies({ data }) {
  const classes = useStyles()
  const breadCrumbs = [
    { id: "home", label: "Home", route: Routes.home },
    { id: "case-studies", label: "Case Studies" }
  ]
  const caseStudyList = data.cms.brsCaseStudies

  return (
    <div>
      <Layout>
        <Hero
          title='Case Studies'
          coverImageMobile={{ url: '/heroSampleMobile.png', alternativeText: "Case Studies" }}
          coverImageWeb={{ url: '/heroSample.png', alternativeText: "Case Studies" }}
        />
        <BreadCrumbComponent crumbsList={breadCrumbs} />
        <div className='mainContainer'>
          <div className={classes.container}>
            <CaseStudyCards
              cardList={caseStudyList}
              showViewAllButton={false}
              featured={false}
            />
          </div>
        </div>
      </Layout>
    </div>
  )
}


export const query = graphql`
query CaseStudyPageQuery {
  cms {
    brsCaseStudies(sort: "order:asc") {
      featureInHomePage
      excerpt
      id
      showInHomePage
      slug
      title
      services
      thumbNailImage {
        url
        alternativeText
      }
    }
  }
}
`

export default CaseStudies
